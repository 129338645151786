import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './App.css';
import React, { useState, useEffect } from 'react'
import Footer from './Footer'
import { Algego, Images } from './images/index.js';

function App() {

  const windowHeight = window.innerHeight / 2
  const [showArrow, setShowArrwo] = useState(false)

  const changeArrowVisibility = () => {
    if (window.scrollY > windowHeight) {
      setShowArrwo(true)
    } else {
      setShowArrwo(false)
    }
  }

  useEffect(() => {
    changeArrowVisibility()
    window.addEventListener("scroll", changeArrowVisibility)
  })

  return (
    <div className='App'>
      <div className='envelopAndArrow'>
        <div className={showArrow ? 'homeArrowShow' : 'homeArrowHide'}>
          <a href='#headerSection'><img src={Images.ArrowGray} alt='Arrow'></img></a>
        </div>
        <div className='envelopShow'>
          <a href='mailto:julieguillaume@algego.com'><img src={Images.EnvelopGray} alt='envelop'></img></a>
        </div>
      </div>
      <div id='headerSection'>
        <div className='algegoTop'>
          <a href='https://algego.com' target='_blank'>
            <img src={Images.AlgegoLogo} alt='ALGEGO'></img>
          </a>
        </div>
      </div>
      <div className='headerTxtSection'>
        <h1>ALGEGO x CSMO</h1>
        <div className='firstLine'>Une collaboration multimédia sur mesure</div>
        <div className='secondLine'>Défiler vers le bas pour découvrir les mandats confiés à ALGEGO depuis 2019<br />
          en communication et en formation</div>
      </div>
      <div className='zoneTimeLine'>
        <VerticalTimeline
          lineColor='#E6E7E9'
          className='timeLine'>

          {/* 1- ALGEGO */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work first-block"
            contentStyle={{ background: '#DD0021', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #DD0021' }}
            date="NOTRE EXPERTISE"
            dateClassName={'date1'}
            iconStyle={{ background: '#E6E7E9' }}
            iconClassName={"algego"}
            icon={Algego}
            position='right'
          >
            <div className='logoBlockContainer logoBlockAlgego'>
              <img className='logoBlock' src={Images.AlgegoLogo} alt='ALGEGO'></img>
            </div>
            <div className='contentContainer'>
              <div className='date date0 date-B'>NOTRE EXPERTISE</div>
              <div className='titreProjet'>
                <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>De l’idée à la diffusion,<br></br> ALGEGO a la solution.</h1>
              </div>
              <div className='algegoBlock'>
                <div className='imageOfproject'>
                  <div className='iframeWrapper iframeWrapper-2'>
                    <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/812542084?h=e8d01614e6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                  </div>
                </div>
                <hr></hr>
                <div className='mandat'>
                  <h3 className="vertical-timeline-element-title">Le couteau suisse de vos formations</h3>
                  <br></br>
                  <p className="vertical-timeline-element-subtitle">Prêt à entrer dans la nouvelle dimension de la formation multimédia ?</p>
                  <br></br>
                  <p>Avec notre couteau suisse : aiguisez les compétences de vos apprenants en créant pour eux des parcours pédagogiques personnalisés !
                  </p>
                </div>
                <div className='imageOfproject'>
                  <div className='iframeWrapper'>
                    <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/873457133?h=b090ff70cc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                  </div>
                </div>
              </div>
            </div>
          </VerticalTimelineElement>

          {/* 8- Horti 2022 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#bbd238', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #bbd238' }}
            date="2023"
            dateClassName={'date date8'}
            iconStyle={{ background: '#E6E7E9', color: '#fff' }}
            iconClassName={"algego"}
            icon={Algego}
            position='left'
          >
            <div className='logoBlockContainer logoBlockHorti'><img className='logoBlock' src={Images.horti_logo} alt='Plasticompetences'></img></div>
            <div className='contentContainer'>
              <div className='date date1 date-B'>2023</div>
              <div className='titreProjet'>
                <h1 style={{ margin: '0' }}><strong>Projet : <em>Parcours RH</em></strong></h1>
                <p>Réalisation d’une vidéo promotionnelle
                </p>
              </div>
              <hr></hr>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Objectif</h3>
                <p className="vertical-timeline-element-subtitle">
                Promouvoir la formation continue destinée aux gestionnaires pour favoriser un plus grand nombre d’inscriptions.
                </p>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Livrable</h3>
                <p className="vertical-timeline-element-subtitle">
                  Direction artistique, réalisation et production d'une vidéo promotionnelle de 45 secondes.
                </p>
              </div>
              <div className='imageOfproject'>
                <div className='iframeWrapper'>
                  <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/886499542?h=9e8fcf8be2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </VerticalTimelineElement>

          {/* 7- CAMAQ 2023 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5096dc', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #5096dc' }}
            date="2023"
            dateClassName={'date date7'}
            iconStyle={{ background: '#E6E7E9', color: '#fff' }}
            iconClassName={"algego"}
            icon={Algego}
            position='right'
          >
            <div className='logoBlockContainer logoBlockCamaq'><img className='logoBlock' src={Images.camaq_logo} alt='CAMAQ'></img></div>
            <div className='contentContainer'>
              <div className='date date1 date-B'>2023</div>
              <div className='titreProjet'>
                <h1><strong>Projet : <em>AMBITION</em></strong></h1>
                <p>Réalisation d’une vidéo promotionnelle</p>
              </div>
              <hr></hr>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Objectif</h3>
                <p className="vertical-timeline-element-subtitle">
                  Pour communiquer sur une de ses formations, le CAMAQ souhaitait réaliser une vidéo promotionnelle corporative ayant pour contenu des témoignages de formateurs et d’anciens participants. Cette vidéo est destinée à être diffusée sur les réseaux sociaux.</p>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Livrable</h3>
                <p className="vertical-timeline-element-subtitle">
                  Direction artistique, réalisation et production de 5 capsules vidéo de 2 min. chacune.</p>
              </div>
              <div className='imageOfproject'>
                <div className='iframeWrapper'>
                  <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/808838313?h=51a5bd3d52&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </VerticalTimelineElement>

          {/* 6- Plasti 2022 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'red', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  red' }}
            date="2022"
            dateClassName={'date date4'}
            iconStyle={{ background: '#E6E7E9', color: '#fff' }}
            iconClassName={"algego"}
            icon={Algego}
            position='left'
          >
            <div className='logoBlockContainer logoBlockPlasti2'><img className='logoBlock' src={Images.plasti_logo} alt='Plasticompetences'></img></div>
            <div className='contentContainer'>
              <div className='date date1 date-B'>2022</div>
              <div className='titreProjet'>
                <h1><strong>Projet : <em>SAVOIR-ÊTRE</em></strong></h1>
                <p>Réalisation de 3 vidéos humoristiques</p>
              </div>
              <hr></hr>

              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Objectif</h3>
                <p className="vertical-timeline-element-subtitle">
                  Permettre aux employés d’acquérir des connaissances sur le savoir-être en entreprise sur un mode humoristique.
                </p>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Les 3 thèmes abordés</h3>
                <ul>
                  <li>Miser sur les forces de l’autre</li>
                  <li>Penser et s’ajuster</li>
                  <li>Le travail d’équipe</li>
                </ul>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Livrable</h3>
                <p className="vertical-timeline-element-subtitle">
                  Direction artistique, réalisation et production de 3 capsules vidéo de 3 min. chacune.
                </p>
              </div>
              <div className='imageOfproject'>
                <div className='iframeWrapper'>
                  <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/811468931?h=fb6dd1c9ea&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </VerticalTimelineElement>

          {/* 5- Enviro 2021-2023 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#00622b', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #00622b' }}
            date="2021-2023"
            dateClassName={'date date5'}
            iconStyle={{ background: '#E6E7E9', color: '#fff' }}
            iconClassName={"algego"}
            icon={Algego}
            position='right'
          >
            <div className='logoBlockContainer logoBlockEnviro'><img className='logoBlock' src={Images.enviro_logo} alt='Envirocompetences'></img></div>
            <div className='contentContainer'>
              <div className='date date1 date-B'>2021-2023</div>
              <div className='titreProjet'>
                <h1><strong>Projet : <em>VERT ton avenir</em></strong></h1>
                <p>Promotion et valorisation de 10 métiers en environnement</p>
              </div>
              <hr></hr>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Publics</h3>
                <p className="vertical-timeline-element-subtitle">
                  <ul>
                    <li>les jeunes de 14 à 24 ans (intérêts, motivations et cursus scolaires divers)</li>
                    <li>les personnes issues de l’immigration (profils, âges et niveaux de français variés)</li>
                  </ul>
                </p>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Mandat</h3>
                <p className="vertical-timeline-element-subtitle">
                  Promouvoir des métiers du secteur de l’environnement auprès des jeunes et des populations immigrées par l’intermédiaire de bornes interactives multimédia.</p>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Défi</h3>
                <p className="vertical-timeline-element-subtitle">
                  Valoriser des métiers peu connus ou mal perçus (préjugés négatifs). Créer une expérience immersive pour favoriser l’engagement des publics cibles vers les métiers de l’environnement.</p>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Solutions</h3>
                <p className="vertical-timeline-element-subtitle">
                  <ul>
                    <li>analyse des métiers pour créer des parcours professionnels types</li>
                    <li>planification et animation de rencontres de discussion avec des experts métiers</li>
                    <li>conception-rédaction des scénarimages (environnement professionnel, journée type d’une journée de travail, jeux-défis interactifs, etc.) propres à chaque métier</li>
                    <li>production des modélisations 3D des environnements de travail et création de personnages animés en <em>motion capture</em></li>
                    <li>programmation d'activités pédagogiques interactives de type jeux vidéo</li>
                    <li>développement d’une application sur mesure pour animer les contenus à la fois sur la borne interactive et sur une version web</li>
                    <li>intégration des contenus dans la borne interactive et sur le site web dédié</li>
                    <li>programmation des outils d’analyse statistique sur le comportement des utilisateurs de la borne interactive</li>
                  </ul>
                </p>
              </div>
              <div className='imageOfproject'>
                <div className='iframeWrapper'>
                  <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/678893128?h=fd32598d78&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </VerticalTimelineElement>

          {/* 4- Plasti 2021 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'red', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  red' }}
            date="2021"
            dateClassName={'date date4'}
            iconStyle={{ background: '#E6E7E9', color: '#fff' }}
            iconClassName={"algego"}
            icon={Algego}
            position='left'
          >
            <div className='logoBlockContainer logoBlockPlasti2'><img className='logoBlock' src={Images.plasti_logo} alt='Plasticompetences'></img></div>
            <div className='contentContainer'>
              <div className='date date1 date-B'>2021</div>
              <div className='titreProjet'>
                <h1><strong>PAMT avec modélisations 3D<br></br> et <em>motion capture</em></strong></h1>
              </div>
              <hr></hr>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Mandat</h3>
                <p className="vertical-timeline-element-subtitle">
                  Optimiser l’appropriation des PAMT traditionnels auprès des employés par l’intégration de capsules vidéo, par la conception d’une expérience immersive pour faciliter la compréhension des étapes de production et favoriser la rétention des nouveaux apprentissages.
                </p>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Défi</h3>
                <p className="vertical-timeline-element-subtitle">
                  Montrer les environnements de travail ainsi que les chaînes de production en usine pour faciliter l’apprentissage des nouveaux employés.
                </p>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Solutions</h3>
                <div className="vertical-timeline-element-subtitle">
                  <ul>
                    <li>faire une analyse technopédagogique des PAMT avec des experts de contenu pour identifier les points d'apprentissage essentiels</li>
                    <li>modéliser en animations 3D toutes les machines, les outils et leurs composantes</li>
                  </ul>
                </div>
              </div>
              <div className='imageOfproject'>
                <div className='iframeWrapper'>
                  <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/563706535?h=6348db9387&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </VerticalTimelineElement>

          {/* 3- 2019 Plasti */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'black', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  black' }}
            date="2019-2021"
            dateClassName={'date date3'}
            iconStyle={{ background: '#E6E7E9', color: '#fff' }}
            iconClassName={"algego"}
            icon={Algego}
            position='right'
          >
            <div className='logoBlockContainer logoBlockPlasti1'><img className='logoBlock' src={Images.plasti_logo} alt='Plasticompetences'></img></div>
            <div className='contentContainer'>
              <div className='date date1 date-B'>2019-2021</div>
              <div className='titreProjet'>
                <h1><strong>Formation hybride multimédia</strong></h1>
                <p>Mise à jour des profils de compétences des métiers en injection et transposition de la formation présentielle <em>Notions de base sur le procédé d’injection des plastiques</em> en formation hybride.</p>
              </div>
              <hr></hr>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Mandat</h3>
                <ul>
                  <li>analyser les informations présentées sur les différents métiers dans l’étude <em>Analyse des besoins de formation pour le sous-secteur de l’injection des matières plastiques</em>
                  </li>
                  <li>réaliser des entrevues dirigées auprès des représentants experts d’entreprises en examinant l’information présentée dans les profils existants et en l’adaptant aux nouvelles réalités des métiers en injection
                  </li>
                  <li>définir des objectifs pédagogiques, élaboration de la structure d’enseignement et planification des opérations et gestion de projet
                  </li>
                  <li>concevoir un parcours de formation et intégrer des stratégies pédagogiques en ligne et en présentielle
                  </li>
                  <li>élaborer des stratégies d’évaluation
                  </li>
                  <li>rédiger le scénario et préparer le découpage technique
                  </li>
                  <li>produire du contenu multimédia (modélisations 3D et <em>motion capture</em>
                    )</li>
                  <li>développer des parcours de formation et intégrer des contenus dans <em>Articulate</em>
                  </li>
                  <li>générer des SCORMS et intégrer la formation dans la plateforme LMS
                  </li>
                </ul>
              </div>
              <div className='imageOfproject'>
                <div className='iframeWrapper'>
                  <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/553482930?h=6b31aedd9e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
              <div className='imageOfproject'>
                <div className='iframeWrapper iframeWrapper-2'>
                  <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/834488613?h=c444a8e240&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </VerticalTimelineElement>

          {/* 2- 2019 CSMO */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#ed6d05', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #ed6d05' }}
            date="2019"
            dateClassName={'date date2'}
            iconStyle={{ background: '#E6E7E9', color: '#fff' }}
            iconClassName={"algego"}
            icon={Algego}
            position='left'
          >
            <div className='logoBlockContainer logoBlockCsmo'><img className='logoBlock' src={Images.csmo} alt='CSMO'></img></div>
            <div className='contentContainer'>
              <div className='date date1 date-B'>2019</div>
              <div className='titreProjet'>
                <h1><strong>États généraux<br></br>de la métallurgie 2018</strong></h1>
              </div>
              <hr></hr>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Livrable</h3>
                <p className="vertical-timeline-element-subtitle">Conception d’un site web « one pager » balisé avec des animations interactives pour créer une expérience immersive sur les États généraux de la métallurgie 2018.
                </p>
              </div>
              <div className='mandat'>
                <h3 className="vertical-timeline-element-title">Objectifs</h3>
                <p className="vertical-timeline-element-subtitle">Raconter, sous la forme d’une progression d’une « timeline », la journée des États généraux de la métallurgie 2018. L’objectif est de mettre en perspective les partenaires (25), les conférenciers (20) et les médias produits lors de cet évènement (17 vidéos) :
                  <ul>
                    <li>présenter les enjeux stratégiques du secteur de la métallurgie</li>
                    <li>créer une communication personnalisée</li>
                    <li>promouvoir les orientations stratégiques par clientèle cible</li>
                    <li>disposer d’une plateforme de diffusion de documents multimédia (textes, vidéos, photos)</li>
                    <li>offrir des appels à l’action pour mener chaque clientèle cible (formulaires de sondage, contact direct avec les personnes-ressources, etc.)</li>
                  </ul>
                </p>
              </div>
              <div className='imageOfproject'>
                <div className='iframeWrapper'>
                  <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/873454586?h=b755075313&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </VerticalTimelineElement>

        </VerticalTimeline>
      </div>
      <Footer />
    </div>
  );
}

export default App;
